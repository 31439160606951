.view-handwritten-notes- {
    .accordion-button {
      color: #0b83c3 !important;
      font-size: 1.0625rem !important;
      font-weight: 500;
    }
    .view-handwritten-notes-accordion-left-text {
      margin-left: 1.7rem;
    }
    .view-handwritten-notes-accordion-right-text {
      margin-left: auto;
      margin-right: 0.7rem;
    }
    ::after {
      position: absolute;
    }
    th:first-child {
      padding-left: 3rem;
    }
    th:last-child {
      padding-right: 3rem;
    }
    td:first-child {
      padding-left: 3rem;
    }
    td:last-child {
      padding-right: 3rem;
    }
  
    .view-details-column-label {
      font-size: 0.9rem;
      font-weight:500;
    }
    .view-details-column-value {
      font-size: 1rem;
     
    }
    .view-sample-table {
      padding-top: 1.5rem;
    }
    .view-sample-table-heading {
      background-color: #e0dcdc !important;
      font-weight: 500;
    }
    .view-sample-table-row {
      height: 4.125rem;
      vertical-align: middle;
    }
    .view-sample-table-col {
      font-size: 0.875rem;
      font-weight: 500;
      height: 1.25rem;
    }
    .vcr-pp-view-sample-test-conducted {
      font-weight: bold;
      vertical-align: middle;
    }
    .vcr-pp-view-sample-no-test-available {
      font-size: larger;
      font-weight: bold;
      text-align: center;
    }
    .view-handwritten-notes-empty-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2rem;
      flex-direction: column;
      gap: 1rem;
    }
    .vcr-pp-view-sample-vertical-line {
      border: 0.0625rem solid #ccc;
      height: 2.25rem;
      margin: 0rem;
      width: 0rem;
      padding: 0rem;
    }
    .vcr-pp-view-sample-action-btn {
      border: none;
      background-color: initial;
      color: #075883;
    }
    .vcr-pp-view-sample-select-input {
      width: max-content;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      height: fit-content;
      font-size: 0.9rem;
    }
    .vcr-pp-view-sample-sub-col {
      font-size: 0.875rem;
      font-weight: 500;
      height: 1.25rem;
      display: flex;
      max-width: max-content;
    }
    .view-handwritten-notes-details-wrapper {
      display: flex;
      justify-content: space-between;
    }
    .view-handwritten-notes-details-content {
      width: 98%;
    }
    .view-handwritten-notes-details-icon-cursor {
      cursor: pointer;
    }
  }
  
.view-sample-tests {
  padding-top: 1.5rem;
  .view-sample-tests-empty-table {
    font-size: larger;
    font-weight: bold;
    text-align: center;
  }
  .view-sample-tests-heading {
    background-color: #e0dcdc !important;
    font-weight: 500;
  }
  .view-sample-tests-row {
    height: 4.125rem;
    vertical-align: middle;
  }
  .view-sample-tests-col {
    font-size: 0.875rem;
    font-weight: 500;
    height: 1.25rem;
  }
  th:first-child {
    padding-left: 3rem;
  }
  th:last-child {
    padding-right: 3rem;
  }
  td:first-child {
    padding-left: 3rem;
  }
  td:last-child {
    padding-right: 3rem;
  }
  .view-sample-tests-vertical-line {
    border: 0.0625rem solid #ccc;
    height: 2.25rem;
    margin: 0rem;
    width: 0rem;
    padding: 0rem;
  }
  .view-sample-tests-action-btn {
    border: none;
    background-color: initial;
    color: #075883;
  }
}

.vcr-pp-not-found-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  .vcr-pp-not-found-page-warning-icon {
    color: var(--bs-form-invalid-color);
  }
}

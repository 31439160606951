.vcr-pp-sample-info-container {
  min-width: 97vw;
  padding-bottom: 2rem;

  .container {
    padding: 1rem;
  }

  .row {
    margin-top: 1rem;
  }

  .vcr-pp-sample-info-header {
    position: fixed;
    top: 2rem;
    min-width: 98vw;
    padding: 1rem 2rem 0.5rem 1rem;
    background-color: #d9d9d9;
    z-index: 99;
  }

  .vcr-pp-sample-info-form {
    margin-top: 5rem;
    position: fixed;
    top: 2rem;
    min-width: 98vw;
    padding: 1rem 1rem 0.5rem 1rem;
    background-color: #d9d9d9;
    z-index: 99;
  }

  .vcr-pp-sample-info-stepper-wrapper {
    padding: 0px;
    margin-left: 12px;
    margin-right: 28px;
  }

  .vcr-pp-sample-info-header-text {
    font-size: 1.5rem;
    padding-top: 0.5rem;
  }

  .vcr-pp-sample-info-header-left-content {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    gap: 1rem;
  }
  .vcr-pp-sample-info-header-center-content
  {
    text-align: center;
    padding-top: 0.8rem;
  }

  .vcr-pp-sample-info-save-create-barcode {
    margin-left: 1rem;
    background-color: #0183c4;
  }
  .vcr-pp-sample-info-hamburger {
    background-color: #0183c4;
  }
  .vcr-pp-sample-info-qrInfo
  {
    text-align:center;
  }
  .vcr-pp-sample-info-qrcode
  {
    width: 40%;
    height:auto;
    margin-top:1rem;
  }
  .vcr-pp-sample-info-qrcode-label
  {
    font-weight: 500;
    margin-top:1rem;
  }
 
  .vcr-pp-sample-info-add-text-btn {
    background-color: #0183c4;
    color: #ffffff;
  }

  .vcr-pp-sample-info-add-text-btn-col {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .vcr-pp-sample-info-btn-text {
    text-transform: uppercase;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .vcr-pp-sample-info-button {
    /* text-align: right;
    flex: auto; */
    display: flex;
    justify-content: flex-end;
  }

  .vcr-pp-sample-info-form-tabs {
    margin-top: 10rem;
  }

  .vcr-pp-sample-info-durationOfResponseContainer {
    display: inline-flex;
    flex-wrap: wrap;
  }

  .vcr-pp-sample-info-durationOfResponseColumn {
    margin-top: -25px;
  }

  .vcr-pp-sample-info-input-field {
    background-color: white;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 0.1rem;
    padding: 2.5rem;

    .row {
      gap: 2rem;
      padding-top: 0.4rem;
    }
  }

  .vcr-pp-sample-info-medication-error{
    display: flex;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: var(--bs-form-invalid-color);

    svg{
      margin-right:0.4rem
    }
  }

  .vcr-pp-sample-info-form-heading {
    font-weight: bold;
    font-size: 21px;
  }
  .vcr-pp-sample-info-medication-addbtn {
    background-color: #0183c4;
  }
  .vcr-pp-sample-info-medication-form-heading {
    font-weight: bold;
    font-size: 21px;
    display: flex;
    justify-content: space-between;
  }
  .vcr-pp-sample-info-medication-action {
    display:flex;
  }
  .vcr-pp-sample-info-input-label {
    font: normal normal normal 13px/18px Roboto;
  }

  .form-label {
    font-weight: 500;
  }
  
  .form-control {
    border: 0.0625rem solid #707070;
    border-radius: 0.3125rem;
  }

  .form-control.is-invalid {
    border: 0.0625rem solid #dc3545;
  }

  .form-select {
    border: 0.0625rem solid #707070;
    border-radius: 0.3125rem;
  }

  .form-select.is-invalid {
    border: 0.0625rem solid #dc3545;
  }

  .form-check-input {
    border: 0.0625rem solid #707070;
  }

  .form-check-input.is-invalid {
    border: 0.0625rem solid #dc3545;
  }

  .vcr-pp-sample-input-addon-btn {
    z-index: 1
  }

  .view-medication-table-col {

    button {
      border: none;
      background-color: initial;
      color: #075883;
    }

    .action-button-vertical-line {
      border: 0.0625rem solid #ccc;
      height: 2.25rem;
      margin: 0rem;
      width: 0rem;
      padding: 0rem;
    }
  }
}
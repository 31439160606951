.medication
{
    .medication-save-btn{
        background-color: #0183c4;
      }
      .form-label {
        font-weight: 500;
      }
      .form-control {
        border: 0.0625rem solid #707070;
        border-radius: 0.3125rem;
      }
    
      .form-control.is-invalid {
        border: 0.0625rem solid #dc3545;
      }
      .row
      {
        padding: 0.3rem;
      }

      .medicaition-form-heading {
        font-weight: bold;
        font-size: 21px;
        display: flex;
        justify-content: space-between;
      }
      
    .modal-dialog
    {
        min-width: max-content;
    }
}
.users-list {
  th:first-child {
    padding-left: 3rem;
  }
  th:last-child {
    padding-right: 3rem;
  }
  td:first-child {
    padding-left: 3rem;
  }
  td:last-child {
    padding-right: 3rem;
  }
  .users-list-header {
    margin-top: 18px;
    margin-bottom: 18px;
  }
  .users-list-page-heading {
    font-size: 1.5rem;
    font-weight: 500;
    padding-top: 0.2rem;
  }
  .users-list-create-user-btn {
    border-style: solid;
    border-color: #0183c4;
    background-color: white;
    color: #075883;
  }
  .users-list-vertical-line {
    border: 0.0625rem solid #ccc;
    height: 2.25rem;
    margin: 0rem 0.3125rem;
    width: 0rem;
    padding: 0rem;
  }
  .users-list-action-btn {
    border: none;
    background-color: initial;
    color: #075883;
  }
  .users-list-heading {
    background-color: #0380c1;
    color: white;
    font-size: 0.9375rem;
  }
  .users-list-react-table-container {
    background-color: white;
  }
  .users-list-app-table {
    overflow: hidden;
  }
  .users-list-col {
    font-size: 0.8125rem;
    vertical-align: middle;
    height: 3.125rem;
  }
  .users-list-text-container
  {
    white-space:nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
    cursor:pointer;
    max-width:13rem,
  }
  .pagination {
    gap: 0.625rem;
    padding: 1.25rem;
  }
  .page-link {
    color: black;
  }
  .page-item.active {
    .page-link {
      background-color: #075883;
      color: white;
      border: none;
    }
  }
  .page-link:focus {
    box-shadow: none;
  }
}

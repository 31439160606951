.vcr-pp-usr-form {
  .row {
    gap: 1.2rem;
  }
  .col {
    margin-top: 0.4375rem;
    margin-bottom: 0.625rem;
  }
  .modal-dialog {
    max-width: 60%;
  }
  .modal-header {
    padding-left: 2.5rem;
    padding-right: 6.25rem;
  }
  .modal-body {
    padding-left: 2.5rem;
    padding-right: 6.25rem;
  }
  .modal-footer {
    justify-content: left;
    padding-left: 2.5rem;
    padding-right: 6.25rem;
  }

  .vcr-pp-usr-form-error-message {
    color: var(--bs-form-invalid-color);
  }
  .vcr-pp-usr-form-invalid-feedback {
    margin-bottom: 0.625rem;
  }
  .vcr-pp-usr-form-save-btn {
    background-color: #0380c1;
  }
  .form-label {
    font-weight: 500;
  }
  .form-control {
    border: 0.0625rem solid #707070;
    border-radius: 0.3125rem;
  }
  .form-control.is-invalid {
    border: 0.0625rem solid #dc3545;
  }
  .form-select {
    border: 0.0625rem solid #707070;
    border-radius: 0.3125rem;
  }
  .form-select.is-invalid {
    border: 0.0625rem solid #dc3545;
  }
}

.vcr-pp-view-pdf-container{
  padding-top: 1rem;
  .vcr-pp-view-pdf-container-heading {
    background-color: #e0dcdc !important;
    font-weight: 500;
  }
  .vcr-pp-view-pdf-container-row {
    margin-bottom: 0.5rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .vcr-pp-view-pdf-container-details-row {
    margin-bottom: 0.5rem;
    padding-top: 2.8rem;
    padding-bottom: 0.8rem;
  }
  .vcr-pp-view-pdf-container-col {
    font-size: 0.875rem;
    font-weight: 500;
    height: 1.25rem;
  }
  .vcr-pp-view-pdf-container-action-col {
    font-size: 0.875rem;
    font-weight: 500;
    height: 1.25rem;
    display: flex;
    max-width: max-content;
  }
  .vcr-pp-view-pdf-container-sub-col {
    margin-left: 0;
  }
  .vcr-pp-view-pdf-container-sub-row {
    padding-left: 0;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .vcr-pp-view-pdf-container-row-label {
    text-align: left;
    font-size: 0.9rem;
    font-weight: 400;
  }
  .vcr-pp-view-pdf-container-row-value {
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
  }
  .vcr-pp-view-pdf-container-select-input-label {
    font-weight: 500;
  }
  .vcr-pp-view-pdf-container-select-input {
    height: fit-content;
    border: 0.0625rem solid #707070;
    border-radius: 0.3125rem;
  }
  
  .vcr-pp-view-pdf-container-vertical-line {
    border: 0.0625rem solid #ccc;
    height: 2.25rem;
    margin-top: 1.5rem;
    width: 0rem;
    padding: 0rem;   
  }
  .vcr-pp-view-pdf-container-action-btn {
    border: none;
    background-color: initial;
    color: #075883;
    padding-top: 2rem;
  }
}

.multi-select-dropdown {
    div div {
        background-color: #ffffff;
    }

    .invalid-multiselect {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 0.875em;
        color: var(--bs-form-invalid-color);
        background: transparent;
    }
    .chip
    {
      white-space: normal;
      justify-content: space-between;
      width: 90%;
    }
}
.vcr-pp-pdf-sign
{
    .row
    {
        padding: 0.7rem;
    }
    .vcr-pp-pdf-sign-label {
        text-align: left;
        font-size: 0.9rem;
      }
      .vcr-pp-pdf-sign-value {
        text-align: left;
        font-size: 1rem;
        font-weight: 500;
        word-wrap: break-word;
      }
}
.vcr-pp-login-container {
  min-width: 100vw;
  background-image: url('../../images/LoginBackground.png');
  background-color: #066093;
  display: flex;
  justify-content: center;
}
.vcr-pp-usr-login {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 11px;
  margin: auto;
  width: 40%;

  opacity: 0.83;

  .form-label {
    font-size: 1.2rem !important;
    font-weight: 500;
    vertical-align: middle !important;
  }
  .form-control {
    background: #fcfeff 0% 0% no-repeat padding-box;
    border: 0.0625rem solid #0a679e;
    border-radius: 0.5625rem;
    opacity: 0.0625rem;
  }

  .row {
    margin: 1.5rem 0;
  }

  .vcr-pp-usr-login-submit-btn {
    text-transform: uppercase;
    background: #0183c4 0% 0% no-repeat padding-box;
    border-radius: 0.5625rem;
    opacity: 1;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
  }
  .vcr-pp-usr-login-horizontal-line {
    border: 0.0625rem solid #4099d5;
  }
  .vcr-pp-usr-login-heading {
    padding-top: 2rem;
    text-align: center;
    color: #000000;
    text-transform: uppercase;
  }
  .vcr-pp-usr-login-sub-heading {
    font-size: 1.375rem;
  }

  .invalid-login-message {
    color: var(--bs-form-invalid-color);
  }
}

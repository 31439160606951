.loading-css {
  z-index: 9999;
  width: 1000rem;
  height: 100vh;
  opacity: 0.5;
  background-color: #e6e6e6;

  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: fixed;

  .spinner {
    top: 50%;
    left: 50%;
    position: fixed;
  }
}

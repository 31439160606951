.vcr-pp-view-sample-container {
  padding-bottom: 2rem;
  .tab-content {
    background-color: #ffffff;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 3rem;
  }
  .vcr-pp-view-sample-container-tab-content-heading {
    font-weight: bold;
  }
  .col {
    gap: 0.5rem;
  }
  .vcr-pp-view-sample-header {
    height: 50px;
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
    margin-top: 1rem;
    margin-bottom: 0.8rem;
  }
  .vcr-pp-view-sample-profile-id {
    font-size: 00.9375rem;
    font-weight: 500;
    width: max-content;
  }

  .vcr-pp-view-sample-editbtn {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.0625rem solid #b3def3;
    color: #075883;
    font-size: 0.875rem;
    border-radius: 0.3125rem;
    width: 8rem;
    height: 2.1875rem;
  }

  .vcr-pp-view-sample-tabs {
    border-bottom-color: #0380c1;
  }

  .vcr-pp-view-sample-tabs-item {
    border-bottom-color: #0380c1;
    border-bottom-width: 1.5rem;
  }
  .vcr-pp-view-sample-vertical-line {
    border: 0.0625rem solid #ccc;
    height: 2.25rem;
    margin: 0rem 0.3125rem;
  }
}

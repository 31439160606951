.patient-list {
  padding-bottom: 2rem;
  .d-flex {
    padding-left: 0;
    padding-right: 0;
  }
  .patient-list-header {
    margin: 18px 3px;
  }
  .patient-list-form-heading {
    font-size: 1.5rem;
    font-weight: 500;
    padding-top: 0.2rem;
  }
  .patient-list-search-inputgroup {
    border-style: solid;
    border-color: #d9d9d9;
    border-bottom-color: #878a8b;
  }
  .patient-list-search-form {
    background-color: #d9d9d9;
    border: none;
  }
  .form-control:focus {
    box-shadow: none;
  }
  .patient-list-search-btn {
    background-color: #d9d9d9;
    border: none;
  }
  .patient-list-create-patient-btn {
    border-style: solid;
    border-color: #0183c4;
    background-color: white;
    color: #075883;
  }

  .patient-list-vertical-line {
    border: 0.0625rem solid #ccc;
    height: 2.25rem;
    margin: 0rem;
    width: 0rem;
    padding: 0rem;
  }
  .patient-list-action-btn {
    border: none;
    background-color: initial;
    color: #075883;
  }
  .patient-list-heading {
    background-color: #0380c1;
    color: white;
    font-size: 1rem;
    height: 3rem;
    vertical-align: middle;
  }
  .patient-list-react-table-container {
    background-color: white;
  }
  /* .patient-list-app-table {
    overflow: hidden;
  } */
  th:first-child {
    padding-left: 3rem;
  }
  th:last-child {
    padding-right: 3rem;
  }
  td:first-child {
    padding-left: 3rem;
  }
  td:last-child {
    padding-right: 3rem;
  }
  tr:nth-child(even) {
    td {
      background-color: #f2f2f2;
    }
  }
  td {
    border-style: none;
  }
  .patient-list-col {
    font-size: 0.9rem;
    vertical-align: middle;
    height: 2.125rem;
  }

  .pagination {
    gap: 0.625rem;
    padding: 1.25rem;
  }
  .page-link {
    color: black;
  }
  .page-item.active {
    .page-link {
      background-color: #075883;
      color: white;
      border: none;
    }
  }
  .page-link:focus {
    box-shadow: none;
  }
  .patient-list-text-container
  {
    white-space:nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
    cursor:pointer;
    max-width:15rem,
  }
  .dropdown ::after {
    content: none;
    border-width: 0;
  }
  .dropdown-toggle{
    padding:0;
    border:none;
    color: #075883;
  }
  .dropdown-menu
  {
    color: #075883;
  }

  .dropdown-item {
    font-size: smaller;
  }

}

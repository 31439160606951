.pp-view-handwritten-notes-container-container {
    padding-bottom: 3rem;
    .col {
      gap: 0.5rem;
    }
    .pp-view-handwritten-notes-container-header {
      height: 50px;
      background: #ffffff 0% 0% no-repeat padding-box;
      opacity: 1;
      margin-left: 0.1rem;
      margin-right: 0.1rem;
      margin-top: 1rem;
      margin-bottom: 0.8rem;
    }
    .pp-view-handwritten-notes-container-cursor-pointer{
      cursor: pointer;
    }
    .pp-view-handwritten-notes-container-vertical-line {
      border: 0.0625rem solid #ccc;
      height: 2.25rem;
      margin: 0rem 0.3125rem;
    }
    .pp-view-handwritten-notes-container-profile-id {
      font-size: 00.9375rem;
      font-weight: 500;
      width: max-content;
    }
    .pp-view-handwritten-notes-container-editbtn {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 0.0625rem solid #b3def3;
      color: #075883;
      font-size: 0.875rem;
      border-radius: 0.3125rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .pp-view-handwritten-notes-container-tabs {
      border-bottom-color: #0380c1;
    }
    .pp-view-handwritten-notes-container-tabs-item {
      border-bottom-color: #0380c1;
      border-bottom-width: 1.5rem;
    }   
    .nav-link {
      font-size: 0.875rem;
      font-weight: 500;
      color: #021017;
    }  
    .nav-link.active {
      font-size: 0.875rem !important;
      font-weight: 500 !important;
      color: #075883 !important;
      background-color: #d9d9d9 !important;
      border-bottom-color: #0380c1 !important;
      border-bottom-width: 0.3rem !important;
      border-bottom-style: solid !important;
    }
    /* .dropdown ::after {
      content: none;
      border-width: 0;
    }
    .dropdown-toggle{
      padding:0;
      border:none;
      color:transparent;
    }
    dropdown-toggle:active{
      box-shadow:none;
      background-color:transparent;
    }
    .dropdown-toggle:focus{
      box-shadow:none;
      background-color:transparent;
    }  
    .dropdown-item {
      font-size: smaller;
    }
    .dropdown-item :focus{
      box-shadow:none;
      background-color:transparent;
    } */    
  }
  
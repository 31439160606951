.steps-container {
  display: flex;
  padding-top: 20px;
  min-width: 93.7vw;

  .step {
    text-align: center;
    flex: 1;
    cursor: default;
    position: relative;
  }

  .step-disabled {
    cursor: not-allowed;
  }

  .step-cursor-pointer {
    cursor: pointer;
  }
  
  .step .line {
    height: 0px;
    border-width: 1px;
    border-style: solid;
    position: absolute;
    top: calc(50% - 22px);
    left: calc(50% + 6px);
    width: 100%;
  }
  
  .step .circle {
    padding: 6px 6px;
    display: inline-block;
    border-radius: 50%;
    margin-bottom: 12px;
  }
  
  .step-active .circle {
    background: #3d84f5;
    color: #fff;
  }
  
  .step-done .circle {
    background: #236ee8;
    color: #eee;
  }
  
  .step-inactive .circle {
    background: #bbb;
    color: #000;
  }
  
  .step-active .label {
    color: #000;
  }
  
  .step-done .label {
    color: #222;
  }
  
  .step-inactive .label {
    color: #888;
  }
  
  .step-done .line {
    border-color: #3d84f5;
  }
  
  .step-inactive .line,
  .step-active .line {
    border-color: #eee;
  }
  
}


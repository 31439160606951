.vcr-pp-view-pdf-signpad{
  .vcr-pp-view-pdf-signpad-canvas{
    border: 0.1rem solid gray;
    margin-bottom: 0.5rem;
  }
  .vcr-pp-view-pdf-signpad-invalid-feedback{
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: var(--bs-form-invalid-color);
    background: transparent;
  }
  .vcr-pp-view-pdf-signpad-input-label {
    font-weight: 500;
    padding-left: 0;
    margin-bottom: 0.5rem;
   }
   .vcr-pp-view-pdf-signpad-btn {
    background-color: #0183c4;
    
  }
  .vcr-pp-view-pdf-signpad-canvas-display {

    display:block;
  }
  .vcr-pp-view-pdf-signpad-canvas-display-none {
    display:none;
  }
 
  .form-label
  {
    font-weight: 500;
  }

  .form-control{
    height: fit-content;
    border: 0.0625rem solid #707070;
    border-radius: 0.3125rem;
  }
  
}

.vcr-pp-view-pdf-render{
  padding-top: 1.5rem;

  .pagination{
  margin-left: 38.5rem;
  }

  .vcr-pp-view-pdf-render-doc-div{
    display: flex
  }
  .vcr-pp-view-pdf-render-page-div{
    overflow-y: scroll;
    max-height: 25rem;
  }
  .vcr-pp-view-pdf-render-page-number-div{
    display: flex;
    justify-content: center;
  }
  .vcr-pp-view-pdf-render-btn{
    border:none;
    border-radius:0.3rem;
    background-color:#0A679E;
    color:azure;
  }
  .vcr-pp-view-pdf-render-pageno{
  padding-top:0.6rem;
   padding-left:0.6rem;
   padding-right: 0.6rem;
  }
}
.view-sample-qrcode
{
    .view-sample-qrcode-qrInfo
  {
    text-align:center;
  }
  .view-sample-qrcode-qr
  {
    width: 40%;
    height:auto;
    margin-top:1rem;
  }
  .view-sample-qrcode-qr-label
  {
    font-weight: 500;
    margin-top:1rem;
  }
}
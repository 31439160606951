.vcr-pp-unauthorized-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap:1.5rem;
  .vcr-pp-unauthorized-page-warning-icon {
    color: var(--bs-form-invalid-color);
  }
}

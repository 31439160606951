.vcr-pp-view-patient-tab-container {
  display: flex;
  .container-fluid {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }
  .view-details-column-label {
    font-size: 0.9rem;
    font-weight:500;
  }
  .view-details-column-value {
    font-size: 1rem;
  }
}

.vcr-pp-usr-password-change {
  .vcr-pp-usr-password-change-text-input {
    margin-top: 0.4375rem;
    margin-bottom: 0.625rem;
  }
  .vcr-pp-usr-password-change-invalid-password-message {
    color: var(--bs-form-invalid-color);
  }
  .invalid-feedback {
    margin-bottom: 0.625rem;
  }
}

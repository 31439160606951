.logged-user-info {
  text-decoration: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  color: var(--bs-body-color);
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  font-size: medium;
  gap: 0.5rem;

  .logged-user-info-header {
    font-size: larger;
    color: black;
    display: flex;
    flex-direction: column;
  }
  
  .navbar-text {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    color: black;
  }

  .logged-user-info-text-link {
    text-decoration: none;
  }

  .logged-user-info-text {
    text-decoration: none;
    font-size: smaller;
    display: flex;
    color: var(--bs-body-color);
    flex-direction: column;
  }

  .logged-user-info-sm-text {
    font-size: x-small;
  }
  .logged-user-info-dropdown ::after {
    content: none;
  }
  .logged-user-info-dropdown-menu {
    left: auto;
    right: 5%;
    padding: 5%;
    min-width: 0%;
  }
  .logged-user-info-dropdown-item {
    color: black;
    font-size: smaller;
  }
  .logged-user-info-dropdown-item:hover {
    color: black;
    font-size: smaller;
  }

}
.navbar-brand{
    cursor: pointer;
}

.vcr-pp-register-patient-container {
  min-width: 97vw;
  padding-bottom: 2rem;

  .container {
    padding: 1rem;
    border-bottom: 0.1rem lightgrey solid;
  }
  .row {
    margin-top: 1rem;
  }
  .vcr-pp-register-patient-form {
    background-color: white;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 5rem;
    padding: 2.5rem;
    padding-right: 10rem;
  }
  .vcr-pp-register-patient-form-data-input {
    gap: 1.5rem;
  }

  .vcr-pp-register-patient-save {
    background-color: white;
    margin: 1rem;
    margin-top: 0;
    display: grid;
    justify-content: flex-end !important;
    padding-bottom: 2.5rem;
    padding-right: 1.5rem;
  }
  .vcr-pp-register-patient-save-btn {
    background-color: #0183c4;
    border-radius: 0.25rem;
    width: 6.25rem;
  }
  .form-label {
    font-size: 1.125rem;
    font-weight: 500;
  }
  .form-control {
    border: 0.0625rem solid #707070;
    border-radius: 0.3125rem;
  }
  .form-control.is-invalid {
    border: 0.0625rem solid #dc3545;
  }
  .form-select {
    border: 0.0625rem solid #707070;
    border-radius: 0.3125rem;
  }
  .form-select.is-invalid {
    border: 0.0625rem solid #dc3545;
  }
  .form-check-input {
    border: 0.0625rem solid #707070;
    border-radius: 0.1875rem;
    width: 1.1875rem;
    height: 1.1875rem;
  }
  .form-check-input:checked {
    background-color: #0183c4;
  }
  .form-check-input.is-invalid {
    border: 0.0625rem solid #dc3545;
  }
  .form-check-label {
    font-size: 1.125rem;
    font-weight: 500;
  }
  .vcr-pp-register-patient-header {
    position: fixed;
    width: 101%;
    padding: 1rem 0.5rem 0.5rem 2rem;
    margin-top: 0;
    margin-left: -3rem;
    margin-right: 1rem;
    z-index: 99;
    background-color: #d9d9d9;
  }
  .vcr-pp-register-patient-header-left-content {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    gap: 1rem;
    padding-left: 1.8rem;
  }
  .vcr-pp-register-patient-header-text {
    font-size: 1.5rem;
    padding-top: 0.5rem;
  }
  .vcr-pp-register-patient-save-add-sample {
    margin-left: 2rem;
  }
}

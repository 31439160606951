.date-input {
  .react-datepicker-wrapper {
    width: 100% !important;
  }
  .react-datepicker__navigation--years {
    background: none;
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    padding: 0;
    border: 0.45rem solid transparent;
    z-index: 1;
    height: 10px;
    width: 10px;
    text-indent: -999em;
    overflow: hidden;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
    top: -4px;
    border-bottom-color: #ccc;
  }

  .react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
    top: 4px;
    border-top-color: #ccc;
  }

  .invalid-date {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: var(--bs-form-invalid-color);
  }
  .react-datepicker__close-icon::after
  {
    padding-bottom:0.313rem;
    width:1.25rem;
    font-size:0.9rem;
  }
}

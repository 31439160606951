.vcr-pp-register-patient-tabs {
  border-bottom-color: #0380c1;

  .nav-link {
    font-size: 0.875rem;
    font-weight: 500;
    color: #021017;
    border:none;
  }

  .nav-link.active {
    font-size: 0.875rem ;
    font-weight: 500 ;
    color: #075883 ;
    background-color: #d9d9d9 ;
    border:none;
    border-bottom-color: #0380c1 ;
    border-bottom-width: 0.3rem ;
    border-bottom-style: solid ;
  }
  :nth-last-child(2)
  {
      margin-left: auto;
      border-right-style: groove;
  }
 
}

.view-details-column {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  .value {
    font-weight: 500;
    word-wrap: break-word;
  }
  .view-details-column-label {
    text-align: left;
    font-size: 0.9rem;
    font-weight: 500;
  }
  .view-details-column-action-btn {
    border: none;
    background-color: initial;
    color: #075883;
    text-align:center ;
  }
  .view-details-column-value {
    text-align: left;
    font-size: 1rem;
    word-wrap: break-word;
  }
  .view-details-column-helper-text{
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: var(--bs-secondary-color);
  }
}
